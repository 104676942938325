import React, { useEffect } from "react";

import "./index.css";
import { useTranslation } from "react-i18next";

export default function Meia() {
  const { t } = useTranslation();
  const empresa = process.env.REACT_APP_NAME_EMPRESA;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container info-containers mt-4 mb-4">
      <div className="row terms">
        <h1>{t("TermsDiscount.halfPrice")}</h1>
        <p>
          {t("TermsDiscount.halfPriceMessage")}{" "}
          {t("Terms.cookiesConsideration3")} {empresa}{" "}
          {t("TermsDiscount.halfPriceMessage2")}
        </p>

        <h4>
          <strong>{t("TermsDiscount.federalLaw")}</strong>
        </h4>

        <p>
          <b>{t("TermsDiscount.federalLawArt1")}</b>{" "}
          {t("TermsDiscount.federalLawArt1Message")}
          <br />
          <b>{t("TermsDiscount.federalLawSection1")}</b>{" "}
          {t("TermsDiscount.federalLawSection1Message")}
          <br />
          <b>{t("TermsDiscount.federalLawSection2")}</b>{" "}
          {t("TermsDiscount.federalLawSection2Message")}
          <br />
          <b>{t("TermsDiscount.federalLawSection3")}</b>{" "}
          {t("TermsDiscount.vetoed")}.
          <br />
          <b>{t("TermsDiscount.federalLawSection4")}</b>{" "}
          {t("TermsDiscount.revoked")}
          <br />
          <b>{t("TermsDiscount.federalLawSection5")}</b>{" "}
          {t("TermsDiscount.revoked")}
          <br />
          <b>{t("TermsDiscount.federalLawSection6")}</b>{" "}
          {t("TermsDiscount.revoked")}
          <br />
          <b>{t("TermsDiscount.federalLawSection7")}</b>{" "}
          {t("TermsDiscount.vetoed")}.
          <br />
          <b>{t("TermsDiscount.federalLawSection8")}</b>{" "}
          {t("TermsDiscount.federalLawSection8Message")}
          <br />
          <b>{t("TermsDiscount.federalLawSection9")}</b>{" "}
          {t("TermsDiscount.federalLawSection9Message")}
          <br />
          <b>{t("TermsDiscount.federalLawSection10")}</b>{" "}
          {t("TermsDiscount.federalLawSection10Message")}
          <br />
          <b>{t("TermsDiscount.federalLawSection11")}</b>{" "}
          {t("TermsDiscount.federalLawSection11Message")}
          <br />
          {t("TermsDiscount.fullLawLink")}{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.planalto.gov.br/ccivil_03/_Ato2011-2014/2013/Lei/L12933.htm"
          >
            http://www.planalto.gov.br/ccivil_03/_Ato2011-2014/2013/Lei/L12933.htm
          </a>{" "}
          <br />
          {t("TermsDiscount.fullDecreeLink")}{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href=" http://www.planalto.gov.br/ccivil_03/_Ato2015-2018/2015/Decreto/D8537.htm"
          >
            http://www.planalto.gov.br/ccivil_03/_Ato2015-2018/2015/Decreto/D8537.htm
          </a>
        </p>

        <h1>{t("TermsDiscount.rightToHalfPrice")}</h1>

        <p>
          <h4>
            <strong>{t("TermsDiscount.students")}</strong>
          </h4>
          {t("TermsDiscount.studentsMessage")}{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.documentodoestudante.com.br/"
          >
            {t("TermsDiscount.clickHere")}
          </a>
        </p>

        <h4>
          <strong>{t("TermsDiscount.elderly")}</strong>
        </h4>
        <p>{t("TermsDiscount.elderlyMessage")}</p>

        <h4>
          <strong>{t("TermsDiscount.specialNeeds")}</strong>
        </h4>
        <p>{t("TermsDiscount.specialNeedsMessage")}</p>

        <h4>
          <strong>{t("TermsDiscount.lowIncome")}</strong>
        </h4>
        <p>{t("TermsDiscount.lowIncomeMessage")}</p>

        <h1>{t("TermsDiscount.whoElseHasRights")}</h1>

        <p>
          <h4>
            <strong>{t("TermsDiscount.bloodDonors")}</strong>
          </h4>
          {t("TermsDiscount.bloodDonorsMessage")}
          <a href="https://procon.es.gov.br/Media/procon/Leis%20Estaduais/Lei%20Estadual%20n%C2%B0%207.737-2004_Meia%20entrada%20para%20doador%20de%20sangue-2.pdf">
            {" "}
            {t("TermsDiscount.stateLaw")}
          </a>
          {t("TermsDiscount.bloodDonorsMessage2")}
        </p>

        <h4>
          <strong>{t("TermsDiscount.journalists")}</strong>
        </h4>
        <p>{t("TermsDiscount.journalistsMessage")}</p>

        <h4>
          <strong>{t("TermsDiscount.teachers")}</strong>
        </h4>
        <p>{t("TermsDiscount.teachersMessage")}</p>

        <h4>
          <strong>{t("TermsDiscount.policeOfficers")}</strong>
        </h4>
        <p>
          {t("TermsDiscount.policeOfficersMessage")}{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.vilavelha.es.gov.br/legislacao/Arquivo/Documents/legislacao/html/L59952018.html"
          >
            {" "}
            {t("TermsDiscount.policeOfficersMessage2")}{" "}
          </a>
          , {t("TermsDiscount.policeOfficersMessage3")}
        </p>
      </div>
    </div>
  );
}
