import React from "react";
import { useTranslation } from "react-i18next";

export default function CookiesTerms() {
  const empresa = process.env.REACT_APP_NAME_EMPRESA;
  const link = process.env.REACT_APP_LINK_EMPRESA;
  const email = process.env.REACT_APP_MAIL_EMPRESA2;
  const { t } = useTranslation();

  return (
    <div className="container mt-4 mb-4">
      <div className="row terms">
        <h1>{t("Terms.cookies")}</h1>
        <p>
          {t("Terms.welcome")} {empresa}! {t("Terms.cookiePolicy")}
          <br></br>
          <br></br>
          {t("Terms.cookiePolicy2")}
          <br></br>
          <br></br>
          {t("Terms.cookiePolicy3")} {empresa} {t("Terms.cookiePolicy4")}{" "}
          {empresa} {t("Terms.cookiePolicy5")}
          <br></br>
          <br></br>
          {t("Terms.cookiePolicy6")}
          <br></br>
          <br></br>
        </p>
        <h4>{t("Terms.definitions")}</h4>
        <p>
          <b>{t("Terms.localStorage")}</b> {t("Terms.localStorageMessage")}
          <br></br>
          <b>{t("Terms.deviceIdentifier")}</b>
          {t("Terms.deviceIdentifierMessage")}
          <br></br>
          <b>{t("Terms.pixelTag")}</b>
          {t("Terms.pixelTagMessage")}
          {empresa}. {t("Terms.pixelTagMessage2")}
          <br></br>
          <b>
            {t("Terms.platform")} {empresa}:
          </b>{" "}
          {t("Terms.platformMessage")} {link}.<br></br>
          <b>{t("Terms.personalData")}</b> {t("Terms.personalDataMessage")}
          <br></br>
        </p>
        <h4>{t("Terms.cookiesConsideration")}</h4>
        <p>
          {t("Terms.cookiesConsideration1")}
          <br></br>
          {t("Terms.cookiesConsideration2")}
          <br></br>
          1.3. {t("Terms.cookiesConsideration3")} {empresa}{" "}
          {t("Terms.cookiesConsideration4")} {empresa}{" "}
          {t("Terms.cookiesConsideration5")}
          <br></br>
        </p>
        <h4>{t("Terms.cookieUtilization")}</h4>
        <p>
          {t("Terms.cookieUtilization2")} {empresa}{" "}
          {t("Terms.cookieUtilization3")}
          <br></br>
          {t("Terms.cookieUtilization4")}
          {empresa};<br></br>
          {t("Terms.cookieUtilization5")}
          <br></br>
          {t("Terms.cookieUtilization6")}
          <br></br>
          {t("Terms.cookieUtilization7")}
          <br></br>
          {t("Terms.cookieUtilization8")}
          <br></br>
          {t("Terms.cookieUtilization9")} {empresa}.<br></br>
          {t("Terms.cookieUtilization10")}
          <br></br>- https://support.google.com/chrome/answer/95647 (Chrome);
          <br></br>-
          https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
          (Firefox);<br></br>-
          https://www.opera.com/help/tutorials/security/cookies/ (Opera);
          <br></br>-
          https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
          (Internet Explorer);<br></br>-
          https://support.apple.com/pt-br/guide/safari/sfri11471/mac (Safari);
          <br></br>-
          https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy
          (Edge).<br></br>
        </p>
        <h4>{t("Terms.cookieUtilization4")}</h4>
        <p>
          {t("Terms.thirdPartyCookies1")} {empresa}{" "}
          {t("Terms.thirdPartyCookies2")}
          {empresa} {t("Terms.thirdPartyCookies3")} {empresa}{" "}
          {t("Terms.thirdPartyCookies4")}
          <br></br>
          {t("Terms.thirdPartyCookies5")}
          <br></br>
          a) "YourAdChoices"{" "}
          <a
            href="http://optout.aboutads.info/?c=2&lang=EN"
            rel="noopener noreferrer"
            target="_blank"
          >
            http://optout.aboutads.info/?c=2&lang=EN
          </a>
          b) "Evidon"{" "}
          <a
            href="http://info.evidon.com/companies?cc=pt_br&external_opt_out=true&opt_out=true&opt_out_only=true"
            rel="noopener noreferrer"
            target="_blank"
          >
            http://info.evidon.com/companies?cc=pt_br&external_opt_out=true&opt_out=true&opt_out_only=true
          </a>
        </p>
        <h4>{t("Terms.trackingTechnologies")}</h4>
        <p>
          {t("Terms.trackingTechnologies1")} {empresa}{" "}
          {t("Terms.trackingTechnologies2")}
          <br></br>
          {t("Terms.trackingTechnologies3")} {empresa}{" "}
          {t("Terms.trackingTechnologies4")} {empresa}.{" "}
          {t("Terms.trackingTechnologies5")} {empresa},{" "}
          {t("Terms.trackingTechnologies6")}
        </p>
        <h4>{t("Terms.generals")}</h4>
        <p>
          {t("Terms.generals1")} {empresa} {t("Terms.generals2")}
          <br></br>
          {t("Terms.generals3")} {empresa} {t("Terms.generals4")} {empresa},{" "}
          {t("Terms.generals5")} {empresa}.<br></br>
          {t("Terms.generals6")}
          <br></br>
          {t("Terms.generals7")}
          <br></br>
          {t("Terms.generals8")} {empresa} {t("Terms.generals9")} {empresa},
          {t("Terms.generals10")} {email}.<br></br>
          {t("Terms.generals11")}
        </p>
      </div>
    </div>
  );
}
