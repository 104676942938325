import React, { useEffect } from "react";
import "./index.css";
import { removeCheckoutData } from "../../../Helpers/checkout";
import { useTranslation } from "react-i18next";

export default function Index() {
  const empresa = process.env.REACT_APP_NAME_EMPRESA;
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
    removeCheckoutData();
  }, []);

  return (
    <div className="container info-containers mt-4 mb-4">
      <div className="row terms">
        <h1>{t("TermsPolitic.purchasePolicy")}</h1>
        <p>
          {t("TermsPolitic.purchasePolicyMessage")} <br />
        </p>

        <h4>
          <strong>{t("TermsPolitic.cancellations")}</strong>
        </h4>

        <p>
          {t("TermsPolitic.cancellationsMessage")} {empresa},{" "}
          {t("TermsPolitic.cancellationsMessage2")}
        </p>

        <h4>
          <strong>{t("TermsPolitic.serviceFee")}</strong>
        </h4>
        <p>
          {t("TermsPolitic.serviceFeeMessage")} {empresa}{" "}
          {t("TermsPolitic.serviceFeeMessage2")}
        </p>
      </div>
    </div>
  );
}
