import React, { useState } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import ticket from "../../assets/svg/tickets.svg";
import useWindowSize from "../../hooks/useWindowSize";
import TicketsListView from "./TicketsListView";
import MaskHelper from "../../Helpers/mask";
import { useTranslation } from "react-i18next";

const TicketsSummaryView = ({ orderData }) => {
  const isMobile = useWindowSize();
  const { t } = useTranslation();
  const [summaryOpened, setSummaryOpened] = useState(false);

  return (
    <Row className="order-resume">
      <Col>
        {isMobile ? (
          <Accordion className="summary-accordion">
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="ticketsSummary"
                className={
                  summaryOpened && isMobile ? "card-header-opened" : ""
                }
                onClick={() => setSummaryOpened(!summaryOpened)}
              >
                <div className="d-flex flex-row align-center justify-content-between">
                  <span>{t("Header.myTickets")}</span>
                  <span className="summary-total">
                    {MaskHelper.numberToLocaleCurrency(orderData?.value || 0)}
                  </span>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="ticketsSummary">
                <Card.Body>
                  <Row className="order-checkout">
                    <TicketsListView tickets={orderData?.tickets} groupByName />
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        ) : (
          <Card>
            <Card.Header>
              <div className="d-flex flex-row align-center justify-content-between">
                <span>{t("TicketInfoView.myTickets")}</span>
                <img src={ticket} alt="ticket icon" />
              </div>
            </Card.Header>
            <Card.Body>
              <Row className="order-checkout">
                <TicketsListView tickets={orderData?.tickets} groupByName />
              </Row>
            </Card.Body>
          </Card>
        )}
      </Col>
    </Row>
  );
};

export default TicketsSummaryView;
